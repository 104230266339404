<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form :model="form" label-width="8rem">
        <el-form-item label="小程序名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="原始id">
          <el-input v-model="form.original"></el-input>
        </el-form-item>
        <el-form-item label="AppID">
          <el-input v-model="form.app_id"></el-input>
        </el-form-item>
        <el-form-item label="AppSecret">
          <el-input v-model="form.app_secret" ></el-input>
        </el-form-item>
        <el-form-item label="小程序二维码">
          <y_upload_img v-model="form.qrcode"></y_upload_img>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">接入检测</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col v-if="form.id" :span="12">
      <div class=" y-pointer">
        <span style="font-size: 14px;color: #606266;">接入状态：</span>
        <el-tag effect="dark" size="mini" :type="form.is_connect ? 'success' : 'info'">
          {{form.is_connect ? '接入正常' : '未接入'}}
        </el-tag>
      </div>
      <div class="y-pointer" v-if="form.is_connect" style="margin-top: 2rem">
        <div class="y-desc" style="font-weight: unset">小程序官方后台当前服务器配置</div>
        <div style="margin-top: .5rem">
          <span style="font-size: 14px;color: #606266;">
            <el-tooltip v-if="!curDomainInList(domain_list.requestdomain)" content="当前域名不在小程序后台服务器配置request合法域名中" placement="top">
              <i  style="margin-right: .2rem;color: red" class="el-icon-info"></i>
            </el-tooltip>
            <span>request合法域名:</span>
          </span>
          <div  v-for="(item,key) in domain_list.requestdomain" class="y-desc" :key="key">{{item}}</div>
        </div>
        <div style="margin-top: .5rem">
          <span style="font-size: 14px;color: #606266;">
            <el-tooltip v-if="!curDomainInList(domain_list.downloaddomain)" content="当前域名不在小程序后台服务器配置downloadFile合法域名中" placement="top">
              <i  style="margin-right: .2rem;color: red" class="el-icon-info"></i>
            </el-tooltip>
            <span>downloadFile合法域名:</span>
          </span>
          <div class="y-desc" v-for="(item,key) in domain_list.downloaddomain" :key="key">{{item}}</div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
export default {
  name: "conf",
  components:{
    y_upload_img,
  },
  data(){
    return{
      form:{
        is_connect:false,
        name:"",
        original:"",
        app_id:"",
        app_secret:"",
        qrcode:""
      },
      domain_list:{
        downloaddomain:[],
        requestdomain:[]
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.channel.wxappInfo().then(res=>{
        this.form = res.info;
        this.domain_list = res.domain_list
      })
    },
    edit(){
      this.$api.channel.wxappEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    curDomainInList(list){
      if(!list)return false;
      return list.includes(location.origin)
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>