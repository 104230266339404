<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form :model="form" label-width="8rem">
        <el-form-item label="购买成功通知">
          <el-input v-model="form.wxapp_buy"></el-input>
          <div class="y-desc">办公:购买成功通知	</div>
          <div class="y-desc">选用关键字:物品名称 交易金额 购买时间</div>
        </el-form-item>
        <el-form-item label="卡券到期前提醒">
          <el-input v-model="form.wxapp_coupon_lose"></el-input>
          <div class="y-desc">办公:服务到期提醒	</div>
          <div class="y-desc">选用关键字:服务名称 到期时间</div>
        </el-form-item>
        <el-form-item label="新订单通知">
          <el-input v-model="form.wxapp_new_order"></el-input>
          <div class="y-desc">办公:新订单通知	</div>
          <div class="y-desc">选用关键字:订单编号 备注	</div>
        </el-form-item>
        <el-form-item label="抽奖结果通知">
          <el-input v-model="form.wxapp_luck_draw"></el-input>
          <div class="y-desc">办公:抽奖结果通知</div>
          <div class="y-desc">选用关键字:奖品名称 抽奖结果</div>
        </el-form-item>
        <el-form-item label="成员加入提醒">
          <el-input v-model="form.wxapp_join"></el-input>
          <div class="y-desc">办公:新成员加入通知</div>
          <div class="y-desc">选用关键字:队员昵称 加入时间 团队描述</div>
        </el-form-item>
        <el-form-item label="核销成功通知">
          <el-input v-model="form.wxapp_cashier" ></el-input>
          <div class="y-desc">办公:核销结果通知</div>
          <div class="y-desc">选用关键字:核销时间 商品名称</div>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="12">
      <div style="margin-bottom: 1rem;" class="y-desc">小程序为订阅消息，用户未订阅时无法送达</div>
      <div style="margin-bottom: 1rem;" class="y-desc">小程序后台配置时需按照对应项提示选用关键字</div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data(){
    return{
      form:{
        wxapp_buy:"",
        wxapp_coupon_lose:"",
        wxapp_new_order:"",
        wxapp_luck_draw:"",
        wxapp_join:"",
        wxapp_cashier:""
      },
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.channel.noticeTplOne().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.channel.noticeTplUpdate(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
  line-height: 1rem;
}
</style>