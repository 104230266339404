<template>
  <div>
    <el-table v-loading="loading" :data="list">
      <el-table-column prop="title" label="入口名称"></el-table-column>
      <el-table-column label="二维码">
        <template #default="s">
          <el-image :src="s.row.code | tomedia" style="width: 8rem;height: 8rem"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="link" label="链接">
        <template #default="s">
          <div @click="copy(s.row.link)" class="y-pointer">{{s.row.link}}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "entry",
  data(){
    return{
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    copy(str){
      this.$copyText(str).then(()=>{
        this.$message.success("复制成功");
      })
    },
    load(){
      this.loading = true;
      this.$api.channel.wxappEntry().then(res=>{
        this.loading = false;
        this.list = res;
      })
    }
  }
}
</script>

<style scoped>

</style>