<template>
  <div>
    <YTabs v-model="active_tab" active-line>
      <YTabPane label="公共链接" name="entry">
        <el-card>
          <entry></entry>
        </el-card>
      </YTabPane>
      <YTabPane label="接入配置" name="conf">
        <el-card>
        <conf></conf>
        </el-card>
      </YTabPane>
      <YTabPane label="消息通知" name="notice">
        <el-card>
        <notice></notice>
        </el-card>
      </YTabPane>
      <YTabPane label="版本发布" name="upload">
        <el-card>
        <upload></upload>
        </el-card>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-model="active_tab" type="border-card" style="min-height: 35rem">-->
<!--      <el-tab-pane label="公共链接" name="entry">-->
<!--        <entry></entry>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="接入配置" name="conf">-->
<!--        <conf></conf>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="消息通知" name="notice">-->
<!--        <notice></notice>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="版本发布" name="upload">-->
<!--        <upload></upload>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </div>
</template>

<script>
import conf from "@/view/channel/wxapp/conf";
import entry from "@/view/channel/wxapp/entry";
import notice from "@/view/channel/wxapp/notice";
import upload from "@/view/channel/wxapp/upload";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "index",
  components:{
    YTabPane,
    YTabs,
    conf,
    entry,
    notice,
    upload
  },
  data(){
    return{
      active_tab:"entry"
    }
  }
}
</script>

<style scoped>

</style>